<template>
  <v-row class="row--35" align="center">
    <v-col lg="5" md="5" cols="12">
      <div class="thumbnail">
        <slot name="thum-img"></slot>
      </div>
    </v-col>
    <v-col lg="7" md="7" cols="12">
      <div class="about-inner inner pt--100 pt_sm--40 pt_md--40" id="over">
        <div class="section-title">
          <h2 class="heading-title">Wie ben ik</h2>
          <p class="description">
            Sinds 2006 ben ik, Rosa Kiekebosch-Wigink, er met hart en ziel voor honderden mensen geweest.
            <br>
            <br>
            Mensen zoals jij en ik, ieder met hun eigen problemen, verdriet en zorgen. Zoveel verschillende mensen, zoveel verschillende levens en allemaal willen we hetzelfde: gelukkig(er) zijn.
            <br>
            <br>
            Ik ben Lifecoach / Levenscoach in de breedste zin van het woord. Mijn specialisatie is mensen leren omgaan met hun Rouw & Verlies.
            <br>
            <br>
            Mijn levenservaring vormt de basis voor mijn werk. Daarnaast heb ik de opleiding tot Academisch Coach / Counsellor en uiteenlopende trainingen op het gebied van zelfontwikkeling gevolgd.
            <br>
            <br>
            Een aantal jaren geleden werd ik verkozen tot de op één na beste coach van Nederland en België.
            Uit de vele gemotiveerde reacties bleek o.a. dat men mij ervaart als toegankelijk, professioneel, authentiek én betrouwbaar. Andere kwaliteiten die werden genoemd: een aandachtige luisteraar, betrokken, intuïtief en verhelderend.
            <br><br>
            <b>Mijn visie: Jouw toekomst hangt van een hoop dingen af maar vooral van jou!</b>
          </p>
        </div>
        <!-- End .section-title -->
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    components: { },
    data() {
      return {};
    },
  };
</script>
