<template>
  <div>
    <!-- Start Header Area -->
    <HeaderOnePageTwo>
      <img
          slot="logo"
          src="../../assets/images/logo/logo-256.png"
          width="64px"
          height="64px"
          alt="Praktijk voor Councelling & Coaching"
      />
    </HeaderOnePageTwo>
    <!-- End Header Area -->

    <!-- Start Slider Area  -->
    <div class="rn-slider-area">
      <div
        class="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-center justify-center bg_image bg_image-rosa"
      >
        <BannerThree>
          <span slot="designation">Lifecoach & Counsellor</span>
          <h1 class="heading-title" slot="heading-title">
            Hallo! ik ben <span>Rosa</span>.
          </h1>
        </BannerThree>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area  -->
    <div class="about-area about-position-top rn-section-gapBottom bg_color--1">
      <div class="about-wrapper">
        <div class="container">
          <AboutFour>
            <img
              slot="thum-img"
              class="w-100"
              src="../../assets/images/about/rosa.jpg"
              alt="About Images"
            />
          </AboutFour>
        </div>
      </div>
    </div>
    <!-- Start About Area  -->

    <!-- Start List Style  -->
    <div class="rn-list-style rn-section-gap bg_color--5" id="coaching">
      <v-container>
        <h2 class="tilte">Coaching</h2>
        <v-row>
          <!-- Start Single List Style  -->
          <v-col
              lg="4"
              md="4"
              sm="6"
              cols="12"
              v-for="(singleList, i) in coachingListItemContent"
              :key="i"
              :class="singleList.class"
          >
            <div class="list-style-inner">
              <h3 class="fontWeight500">{{ singleList.title }}</h3>
              <p>
                {{ singleList.desc }}
              </p>
              <ul class="list-style--1">
                <li v-for="list in singleList.listContent" :key="list.id">
                  <i v-html="iconSvg(list.icon, singleList.icon_stroke)"></i>
                  {{ list.desc }}
                </li>
              </ul>
            </div>
          </v-col>
          <!-- End Single List Style  -->
        </v-row>
      </v-container>
    </div>
    <!-- End List Style  -->

    <!-- Start Column Area  -->
    <div class="rn-column-area rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="single-column" id="contact">
              <h2 class="tilte">Contact</h2>
              <p>
                Wanneer je een afspraak wilt maken of wanneer je meer informatie wilt, dan gaat mijn voorkeur ernaar uit dat je me belt.
                <br>
                Mijn ervaring is dat dit het persoonlijkst en efficiëntst werkt.
                <br>
                Het is belangrijk dat jij je comfortabel voelt. Dus wanneer je liever mailt is dit natuurlijk ook helemaal oké.
              </p>

              <ul class="list-style--1">
                <li v-for="list in contactListItem" :key="list.id">
                  <i v-html="iconSvg(list.icon)"></i>
                  <div v-html="list.desc"></div>
                </li>
              </ul>

            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Column Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
  import HeaderThree from "../../components/header/HeaderThree";
  import BannerThree from "../../components/slider/BannerThree";
  import AboutFour from "../../components/about/AboutFour";
  import BrandTwo from "../../components/brand/BrandTwo";
  import PortfolioFour from "../../components/portfolio/PortfolioFour";
  import Contact from "../../components/contact/Contact";
  import Footer from "../../components/footer/FooterTwo";
  import HeaderOnePage from "@/components/header/HeaderOnePage.vue";
  import HeaderOnePageThree from "@/components/header/HeaderOnePageThree.vue";
  import HeaderOnePageTwo from "@/components/header/HeaderOnePageTwo.vue";
  import feather from "feather-icons";

  export default {
    components: {
      HeaderOnePageTwo,
      HeaderOnePageThree,
      HeaderOnePage,
      HeaderThree,
      BannerThree,
      AboutFour,
      BrandTwo,
      PortfolioFour,
      Contact,
      Footer,
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "List Style",
            to: "",
            disabled: true,
          },
        ],
        contactListItem: [
          {
            id: 1,
            icon: "home",
            desc: `Alingcamp 51A`,
          },
          {
            id: 2,
            icon: "mail",
            desc: `8111 BV Heeten`,
          },
          {
            id: 3,
            icon: "phone-call",
            desc: `06 48 64 75 61`,
          },
          {
            id: 4,
            icon: "at-sign",
            desc: "<a href=\"mailto:info@rosakiekebosch.nl\">info@rosakiekebosch.nl</a>",
          },
          {
            id: 5,
            icon: "compass",
            desc: "<a href=\"https://goo.gl/maps/64ZYWzaBsVupvP7XA\" target='_blank'>Route</a>",
          },
        ],
        coachingListItemContent: [
          {
            class: "",
            title: "Aandachtspunten",
            desc:  "Hier richt ik me op bij je begeleiding",
            icon_stroke: "3",
            listContent: [
              {
                id: 1,
                icon: "heart",
                desc: `Vergroten eigenwaarde en innerlijke kracht`,
              },
              {
                id: 2,
                icon: "heart",
                desc: `Effectief communiceren`,
              },
              {
                id: 3,
                icon: "heart",
                desc: `Grenzen aangeven en bewaken`,
              },
              {
                id: 4,
                icon: "heart",
                desc: `Verhogen energie`,
              },
              {
                id: 5,
                icon: "heart",
                desc: `Balans tussen ratio en gevoel`,
              },
            ],
          },
          {
            class: "mt_sm--30",
            title: "Aanbod",
            desc: `Wat je van mij mag verwachten`,
            icon_stroke: "3",
            listContent: [
              {
                id: 1,
                icon: "heart",
                desc: `Mijn onvoorwaardelijke en oprechte aandacht`,
              },
              {
                id: 2,
                icon: "heart",
                desc: `Gedegen en vlotte analyse waardoor datgene wat aandacht nodig heeft de aandacht krijgt die het moet krijgen`,
              },
              {
                id: 3,
                icon: "heart",
                desc: `Het delen van mijn, ter zake doende, kennis`,
              },
              {
                id: 4,
                icon: "heart",
                desc: `Mijn hulp bij het persoonlijk ontwikkelen van jezelf `,
              },
            ],
          },
          {
            class: "mt_sm--30 mt_md--30",
            title: "Stijl",
            desc: `Mijn stijl van werken`,
            icon_stroke: "3",
            listContent: [
              {
                id: 1,
                icon: "heart",
                desc: `Lichtheid en humor naast diepgang en intensiteit`,
              },
              {
                id: 2,
                icon: "heart",
                desc: `Confrontatie vanuit respect`,
              },
              {
                id: 3,
                icon: "heart",
                desc: `Creativiteit / inventiviteit in mijn denken en in de op maat gemaakte opdrachten voor jou`,
              },
            ],
          },
        ],
      };
    },
    methods: {
      iconSvg(icon, stroke = 2, fill = 'none') {
        return feather.icons[icon].toSvg({'stroke-width': stroke, 'fill': fill});
      },
    },
  };
</script>
